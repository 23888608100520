<template>
     <v-row justify="center">
    <v-dialog v-model="shower" scrollable  max-width="500px" persistent>
      <v-card>
        <v-card-title>{{ $t('trashPage.messageboxheader')}}<v-spacer></v-spacer>
          <v-icon :color="color.theme" dense dark size="35">delete</v-icon>
        </v-card-title>
        <v-divider></v-divider>
              <v-card-text class="pa-1 pt-1 pb-1" style="height: 500px;" >
                <p class="pl-4 ma-2"><v-icon color="amber">error_outline</v-icon><b>{{ $t('trashPage.messageboxsubheader2')}}</b><v-icon color="amber">error_outline</v-icon></p>
                <p class="pl-5"><v-card-text><v-icon color="red"> mdi-file  </v-icon> <b>{{$t('Youwanttodelete')}} {{file.length}}  {{$t('file')}}</b></v-card-text></p>
                <div v-for="(item,i) in this.file" :key="i">
                  <v-banner class="pa-1 pt-1 pb-1 pl-1">
                    {{i+1}}
                    <v-icon color="red">{{ item.file_type === "folder" ? "folder" : "mdi-file " }}</v-icon>
                    {{item.file_name.length >= 20 ? item.file_name.substring(0,25)+"...": item.file_name}}
                  </v-banner>
                </div>
              </v-card-text>
        <v-divider></v-divider>
         <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary darken-1"  outlined :disabled="loaddataprogress"  @click="$emit('closedialog')">{{ $t('trashPage.canceldelete')}}</v-btn>
            <v-btn color="red"  dark :loading="processdelete" @click="$emit('multidelete')">{{ $t('trashPage.forcedelete')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import InfiniteLoading from "vue-infinite-loading";
import Swal from "sweetalert2/dist/sweetalert2.js";
export default {
        props:["show","file","processdelete"],
        data () {
      return {
          loaddataprogress:false,
      }
    },
    methods:{
    },
      computed: {
    ...mapState(["username", "authorize", "account_active","color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataAccountId"
    ]),
    shower: {
      get() {
        if (this.show === true) {
             console.log(true);
             console.log(this.file);
        }
        return this.show;
      }
    }
      }
}
</script>
 <style>
 </style>